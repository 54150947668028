<!--订单确认页面-->
<template>
    <div class="body">
        <!--地址选择-->
        <div @click="viewAddress">
            <div class="address-wrapper" v-if="orderType == 'wuliu' && addressId">
                <div class="address-info-wrapper">
                    <div class="address">{{addressInfo.detail}}</div>
                    <div class="address-user-wrapper">
                        <div>{{addressInfo.name}}</div>
                        <div>{{addressInfo.mobile}}</div>
                    </div>
                </div>
                <van-icon name="arrow" />
            </div>
            <div class="address-wrapper" v-if="orderType == 'wuliu' && !addressId">
                <div>请选择收货地址</div>
                <van-icon name="arrow" />
            </div>
        </div>
        <!--商品-->
        <div class="product-wrapper">
            <!--这层循环商家-->
            <div class="product-item-wrapper" v-for="item,index in productList" :key="index">
                <img class="product-img" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.trademark">
                <!--这层循环商品-->
                <div class="product-info-wrapper">
                    <div class="product-base-info-item-wrapper" v-for="item2,index2 in item.goods" :key="index2">
                        <div class="product-base-info-wrapper">
                            <div class="product-name">{{item2.name}}</div>
                            <div class="product-price-number">
                                <div class="product-price">{{moneyFen2Yuan(item2.mallprice)}}</div>
                                <div class="product-number">X {{item2.number}}</div>
                            </div>
                        </div>
                        <div class="product-specs" v-if="item2.specs[0] != ''">
                            规格：<span v-for="item3,index3 in item2.specs" :key="index3">{{item3}} </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-tatal-price-warppwe">
                <span>总计：</span>
                <span class="red-price">￥{{moneyFen2Yuan(totalFee)}}</span>
                <span class="red-price" v-if="currentUserCouponMoney">(已满减{{moneyFen2Yuan(currentUserCouponMoney)}}元)</span>
            </div>
        </div>
        <!--备注信息-->
        <div class="remark-wrapper">
            <div>
                <span>备注：</span>
                <input type="text" class="remark-input" placeholder="选填，输入60字以内" v-model="remark">
            </div>
        </div>
        <!--优惠券-->
        <div class="coupon-wrapper">
            <div>优惠券</div>
            <div class="coupon-have" v-if="isHaveCoupon && userCouponId == ''" @click="viewCoupon">选择优惠券</div>
            <div class="coupon-active" v-else-if="isHaveCoupon && userCouponId" @click="viewCoupon">{{currentUserCouponName}}  <van-icon name="arrow" /></div>
            <div class="coupon-default" v-else>暂无可用优惠券</div>
        </div>
        <!--支付栏-->
        <div class="pay-wrapper">
            <div class="pay-total-wrapper">合计：<span>￥{{moneyFen2Yuan(totalFee)}}</span></div>
            <div class="pay-button" @click="createOrder">立即支付</div>
        </div>
        <float-nav v-if="isWeiXinApp"></float-nav>
    </div>
</template>

<script>
  import { getNowBuyProduct } from '../../utils/cart'
  import { globalConfig } from '../../utils/mixin'
  import { getUrlParams, moneyFen2Yuan } from '../../utils/tools'
  import FloatNav from '../../components/common/FloatNav'
  import wx from 'weixin-js-sdk'

  export default {
    name: 'GoodsOrder',
    components: { FloatNav },
    mixins: [globalConfig],
    data () {
      return {
        // 购物车列表
        goodsCartList: [],
        // 订单金额
        totalFee: 0,
        // 商品信息列表
        productList: [],
        // 地址信息
        addressInfo: [],
        // 地址id
        addressId: 0,
        // 下单备注
        remark: '',
        // 是否有优惠券
        isHaveCoupon: false,
        // 当前选择的优惠券id
        userCouponId: 0,
        // 当前选择优惠券名称
        currentUserCouponName: '',
        // 当前选择优惠券金额
        currentUserCouponMoney: 0,
        // 订单类型，虚拟、物流等
        orderType: '',
        // 循环查询的次数，用以递增循环时间
        timeOutNumber: 1,
        // 是否正在轮询订单
        isWatchCreateOrder: 0,
        isWeiXinApp: false
      }
    },
    methods: {
      moneyFen2Yuan,
      // 初始化
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/init/payoff', {
          goods: that.goodsCartList,
          addressid: this.addressId,
          userCouponid: this.userCouponId
        }).then((response) => {
          const data = response.data
          if (data.status === 1) {
            that.productList = data.productInfo
            that.totalFee = data.total_fee
            that.addressInfo = data.address
            that.isHaveCoupon = data.isHaveCoupon
            that.orderType = data.type
            if (that.userCouponId) {
              that.currentUserCouponName = data.expand.userCouponInfo.title
              that.currentUserCouponMoney = data.expand.userCouponInfo.money
            }
            // 如果地址id为空，证明当前没有选择地址，则使用默认的
            if (that.addressId) {} else {
              that.addressId = data.address.id
            }
          } else {
            this.$router.push('/home')
          }
        })
      },
      // 确认下单
      createOrder: function () {
        console.log('当前选择用户优惠券id = ' + this.userCouponId)
        console.log('当前选择地址 = ' + this.addressId)
        const that = this
        if (that.orderType === 'wuliu' && !that.addressId) {
          that.$toast('请选择收货地址')
          return
        }
        // 避免轮询的时候用户一直点击
        if (that.isWatchCreateOrder === 1) {
          return
        }
        this.axios.post(this.apiUrl + 'mall/pay/createOrder', {
          goods: that.goodsCartList,
          addressid: this.addressId,
          userCouponid: this.userCouponId,
          remark: this.remark
        }).then((response) => {
          const data = response.data
          if (data.status === 1) { // 下单成功
            that.watchCreateOrder(data.orderID)
          } else if (data.status === -2) { // 有待支付订单
            that.$dialog.alert({
              title: '提示',
              message: '您有一笔待支付订单，请先前往处理'
            }).then(() => {
              that.$router.push('/orderList/20')
            })
          } else { // 其余情况，大部分都是status = 0的情况，跳转去首页
            that.$dialog.alert({
              title: '提示',
              message: data.msg
            }).then(() => {
              that.$router.push('/home')
            })
          }
        })
      },
      // 监听创建订单
      watchCreateOrder: function (orderId) {
        const that = this
        that.isWatchCreateOrder = 1
        this.axios.post(this.apiUrl + 'mall/order/getOrderResult', {
          orderID: orderId
        }).then((response) => {
          const data = response.data
          if (data.status === 1) { // 成功返回，继续判断具体结果
            const orderStatus = data.info.status
            if (orderStatus === 1) { // 下单成功
              this.$router.push('/payment/' + orderId)
            } else if (orderStatus === -1) { // 库存不足
              that.$dialog.alert({
                title: '提示',
                message: data.info.msg
              }).then(() => {
                that.$router.push('/home')
              })
            } else if (orderStatus === -2) { // 有待支付订单
              that.$dialog.alert({
                title: '提示',
                message: '您有一笔待支付订单，请先前往处理'
              }).then(() => {
                that.$router.push('/orderList/20')
              })
            }
          } else if (data.status === -1) { // 状态-1，继续轮询
            if (that.timeOutNumber >= 4) { // 轮询次数太多，就不要再轮询了。一共轮询4次
              that.$toast({
                message: '请稍候再试',
                onClose: function () {
                  that.$router.push('/home')
                }
              })
              return
            }
            setTimeout(function () {
              that.timeOutNumber++
              that.watchCreateOrder(orderId)
            }, 1000 * that.timeOutNumber * 2)
          }
        })
      },
      // 前往查看地址
      viewAddress: function () {
        this.$store.commit('setLastOrderRoute', this.$route.fullPath)
        this.$router.push('addressList/order')
      },
      // 查看优惠券
      viewCoupon: function () {
        this.$store.commit('setLastOrderRoute', this.$route.fullPath)
        this.$router.push('/selectCoupon')
      }
    },
    mounted () {
      this.addressId = getUrlParams('addressId')
      this.userCouponId = getUrlParams('userCouponId') ? getUrlParams('userCouponId') : 0 //
      this.goodsCartList = getNowBuyProduct()
      this.init()
      // 如果是微信小程序显示一下悬浮导航，部分用户在这个页面没办法返回
      const that = this
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          that.isWeiXinApp = true
        }
      })
    }
  }
</script>

<style scoped>
    .body {
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        max-width: 100%;
        background-color:#eee;
    }
    .address-wrapper {
        width: 100%;
        background-color: #ffffff;
        padding: 1rem;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 0.7rem;
    }
    .address {
        font-size: 1rem;
        color: #3f3f3f;
    }
    .address-info-wrapper {
        width: 90%;
        display: flex;
        flex-flow: column;
    }
    .address-user-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin-top: 0.3rem;
    }
    .address-user-wrapper > div {
        color: #969696;
    }
    .address-user-wrapper > div:nth-child(2) {
        margin-left: 1rem;
    }
    .product-wrapper {
        display: flex;
        flex-flow: column;
        background-color: #ffffff;
        margin: 0 0 0.7rem;
        box-sizing: border-box;
        padding: 1rem;
    }
    .product-item-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        padding: 1rem 0;
    }
    .product-img {
        width: 4.5rem;
        height: 4.5rem;
    }
    .product-info-wrapper {
        width: 76%;
        min-height: 4.5rem;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    .product-base-info-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }
    .product-base-info-item-wrapper {
        padding: 0.5rem 0;
    }
    .product-name  {
        width: 80%;
        color: #3f3f3f;
        font-size: 1rem;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
    }
    .product-price-number {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        justify-content: space-between;
    }
    .product-price {
        color: #7c7c7c;
        font-size: 0.8rem;
    }
    .product-number {
        color: #7c7c7c;
        font-size: 0.8rem;
    }
    .product-specs {
        color: #7c7c7c;
        font-size: 0.8rem;
    }
    .product-tatal-price-warppwe {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-end;
        border-top: 0.1rem solid #e6e6e6;
        padding-top: 0.5rem;
        font-size: 0.9rem;
    }
    .red-price {
        color: #ff3a35;
    }
    .coupon-wrapper {
        padding: 1rem;
        background-color: #ffffff;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
    }
    .coupon-wrapper > div:first-child {
        font-size: 1rem;
        color: #3f3f3f;
    }
    .coupon-default {
        font-size: 1rem;
        color: #b0b0b0;
    }
    .coupon-have {
        color: #ffffff;
        background-color: #ff3a35;
        font-size: 0.85rem;
        border-radius: 0.1rem;
        padding: 0.2rem 0.5rem;
    }
    .coupon-active {
        font-size: 1rem;
        color: #ff3a35;
        display: flex;
        flex-flow: row;
        align-items: center;
    }
    .pay-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #ffffff;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }
    .pay-button {
        padding: 1.5rem 3rem;
        background-color: #ff3a35;
        color: #ffffff;
    }
    .pay-total-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        padding-left: 1rem;
        font-size: 1rem;
    }
    .pay-total-wrapper > span {
        color: #ff3a35;
    }
    .remark-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        width: 100%;
        margin-bottom: 0.7rem;
        padding: 0.7rem 0;
    }
    .remark-wrapper > div {
        background-color: #eee;
        width: 95%;
        padding: 0.5rem 1rem;
        box-sizing: border-box;
        display: flex;
        flex-flow: row;
        align-items: center;
    }
    .remark-input {
        width: 80%;
        border: none;
        background-color: #eeeeee;
        /*margin-left: 1rem;*/
    }
</style>
